import styled, { css } from "styled-components"

import { device, core } from "@src/style"

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media ${device.tablet} {
    flex-direction: row;
    justify-content: space-between;
  }
`

export const Header = styled(core.H3)`
  font-weight: 700;
`

export const Paragraph = styled(core.PL)`
  margin-top: 16px;
`

export const Text = styled.div`
  
  @media ${device.tablet} {
    margin-right: 64px;
  }
`
