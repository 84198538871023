import React, { useState, useEffect, Fragment } from "react"
import { StyledMessage } from "@components/common/styledMessage"

import Nav from "@components/nav/nav"
import IndexPage_Header from "@src/components/main/previewer/indexpage_header"
import IndexPage_Docs from "@components/main/indexpage_docs"
import IndexPage_Faq from "@components/main/indexpage_faq"
import IndexPage_Who from "@components/main/indexpage_who"
import { LocalizedLink, localizedUrl } from "@src/components/link"

import ModalSignIn from "@src/components/main/signin/modal_signin.js"
import { ModalSignInContext } from "./context"

import ActivateButton from "./activatebutton"

import API from "@src/common/api.js"
import ARCPanel from "@components/arcPanel.js"
import {
  Desktop,
  Mobile,
  Left,
  Right,
  FullscreenWrapper,
  Header,
  IndexpageRoot,
  Previewer,
} from "@components/main/styles"
import * as Styles from "./styles"
import * as GlobalStyle from "@src/style/globalStyle"
import { core } from "@src/style"
import { injectIntl } from "react-intl"

export default () => {
  const [code, setCode] = useState(undefined)
  const [showSignIn, setShowSignIn] = useState(false)

  useEffect(() => {
    setCode(API.getParameterByName("preLicenseCode"))
  }, []) // componentDidMount

  const Text = injectIntl(({ intl: { locale }, mobile }) => {
    const CH = Styles.Header
    const CP = Styles.Paragraph
    return (
      <Styles.Text>
        <CH>
          <StyledMessage id="activation.welcome" />
        </CH>
        <CP>
          <StyledMessage id="activation.reason" />
        </CP>
        {code ? (
          <CP>
            <StyledMessage
              id="activation.claim"
              values={{
                code: code,
                learn: (...msg) => (
                  <LocalizedLink
                    to="/blog/2021-06-12-arcpanel-pro/"
                    locale={locale}
                  >
                    {msg}
                  </LocalizedLink>
                ),
              }}
            />
          </CP>
        ) : (
          <CP>
            <StyledMessage id="activation.no_code" />
          </CP>
        )}
      </Styles.Text>
    )
  })

  return (
    <IndexpageRoot>
      <ModalSignInContext.Provider
        value={{
          setShowSignIn: setShowSignIn,
        }}
      >
        <Nav activation />
        <FullscreenWrapper>
          <GlobalStyle.LandingRow firstPromo>
            <GlobalStyle.LandingRowContent paddingTop>
              <GlobalStyle.On mobile tablet>
                <Styles.Row>
                  <Text mobile />
                  <ActivateButton />
                </Styles.Row>
              </GlobalStyle.On>
              <GlobalStyle.On laptop>
                <Styles.Row>
                  <GlobalStyle.Column>
                    <Text />
                  </GlobalStyle.Column>
                  <ActivateButton />
                </Styles.Row>
              </GlobalStyle.On>
            </GlobalStyle.LandingRowContent>
          </GlobalStyle.LandingRow>
        </FullscreenWrapper>

        <IndexPage_Faq activation />
        <IndexPage_Who />
        <ModalSignIn isOpen={showSignIn} setIsOpen={setShowSignIn} activation />
      </ModalSignInContext.Provider>
    </IndexpageRoot>
  )
}
