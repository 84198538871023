import React from 'react'

import Layout from '../components/layout/rootLayout.js'
import ActivatePage from '../components/activate/activatepage.js'

const Default =  ({ pageContext: { locale } }) => (
  <Layout locale={locale}>
    <ActivatePage />
  </Layout>
)


export default Default;