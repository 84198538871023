import React, { useState, useEffect, Fragment } from "react"
import API from "@src/common/api.js"

import styled from "styled-components"

import { StyledMessage } from "@components/common/styledMessage"
import { BlueButton, YellowButton } from "@src/components/buttons/style"
import { CenterColumn } from "@src/style/globalStyle"
import { ModalSignInContext } from "./context"

const InvalidButton = styled(BlueButton)`
  width: 300px;
  height: 70px;
`

const ValidButton = styled(YellowButton)`
  margin-top: 1rem;
  width: 300px;
  height: 70px;
`

export default () => {
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState({})

  const loadingCallback = ({ loading }) => {
    setLoading(loading)
  }

  const componentDidMount = () => {
    const urlCode = API.getParameterByName("preLicenseCode")
    if (!urlCode) {
      setLoading(false)
      setData({
        code: urlCode,
        codeValid: false,
      })
      return
    }

    API.preLicenseCodeValid(urlCode, loadingCallback)
      .then((response) => {
        setData({
          code: urlCode,
          codeValid: response.valid,
        })
      })
      .catch((error) => {
        console.error(error)
      })
  }

  useEffect(componentDidMount, []) // componentDidMount

  return (
    <>
      {loading && <StyledMessage id="activate_button.loading" />}
      {!loading && (
        <>
          {data.codeValid ? (
            <ModalSignInContext.Consumer>
              {({ setShowSignIn }) => {
                return (
                  <ValidButton
                    onClick={() => {
                      setShowSignIn(true)
                    }}
                  >
                    <StyledMessage id="activate_button.activate" />
                  </ValidButton>
                )
              }}
            </ModalSignInContext.Consumer>
          ) : (
            <InvalidButton>
              <StyledMessage id="activate_button.invalid_code" />
            </InvalidButton>
          )}
        </>
      )}
    </>
  )
}
